body {
    background-color: #303135;
}
.input-lna input{
    cursor: pointer !important;
}

a {
    color:#806514
}

a:hover {
    color:#fbbd08
}

label {
    text-align: left;
}

.black {
    border-radius: 2%;
}

.selection {
    border-radius: 0% !important;
}

.ui.checkbox label {
    color: #fbbd08 !important;
}

.ui.checkbox label::before {
    background: #806514 !important;
    border-color: #806514 !important;
}

.ui.checked.checkbox label::after {
    background: #fbbd08 !important;
}

.open {
    margin-top: 50px !important;
}

div.divider.text {
    color: #fbbd08;
}

hr {
    margin-top: 15px;
    height: 1px;
    border: 0;
    background-color: #3a3b3c;
}